import React, { useRef, useState } from "react";
import { DateRangeSelector } from "../DateRange";
import { TransactionSorting } from "ee-nestjs-interfaces";
import { TransactionTable } from "../Table";
import { useNavigate } from "react-router-dom";
import { Dropdown } from "../Dropdown";
import { DowloadCSVButton } from "../DownloadCSVButton";
import { TextInput } from "../Input";
export var Transactions = function (_a) {
    var _b, _c;
    var resourceUrlBase = _a.resourceUrlBase, earliestTransaction = _a.earliestTransaction, isNoc = _a.isNoc;
    var _d = useState([
        new Date(),
        new Date(),
    ]), dateRange = _d[0], setDateRange = _d[1];
    var navigate = useNavigate();
    var _e = useState(TransactionSorting.SESSION_START), sortState = _e[0], setSortState = _e[1];
    var faultCodeFilterInput = useRef(null);
    var maxPeakPowerInput = useRef(null);
    var apiSearchParams = new URLSearchParams();
    apiSearchParams.append("useNoc", isNoc.toString());
    if (sortState) {
        apiSearchParams.append("sort", sortState);
    }
    if ((_b = faultCodeFilterInput.current) === null || _b === void 0 ? void 0 : _b.value) {
        apiSearchParams.append("faultCode", faultCodeFilterInput.current.value);
    }
    if ((_c = maxPeakPowerInput.current) === null || _c === void 0 ? void 0 : _c.value) {
        apiSearchParams.append("maxPeakPower", maxPeakPowerInput.current.value);
    }
    return (React.createElement("div", { className: "flex flex-col gap-y-[32px]" },
        React.createElement(DateRangeSelector, { dateRange: dateRange, setDateRange: setDateRange, firstDate: new Date(earliestTransaction) }),
        React.createElement("div", { className: "flex flex-row gap-x-[32px] items-center" },
            React.createElement("label", { className: "font-N7 text-black-600" }, "Sort By"),
            React.createElement(Dropdown, { items: [
                    {
                        name: "Session Start",
                        value: TransactionSorting.SESSION_START,
                        selectedLabel: "Select",
                    },
                    {
                        name: "Session End",
                        value: TransactionSorting.SESSION_END,
                    },
                    {
                        name: "Energy Delivered",
                        value: TransactionSorting.ENERGY_DELIVERED,
                    },
                    {
                        name: "Transaction Amount",
                        value: TransactionSorting.TRANSACTION_AMOUNT,
                    },
                    { name: "Charger ID", value: TransactionSorting.CHARGER_ID },
                    { name: "Peak Power", value: TransactionSorting.PEAK_POWER },
                ], onClick: setSortState, currentSelection: sortState }),
            isNoc && (React.createElement(React.Fragment, null,
                React.createElement("label", { className: "font-N7 text-black-600" }, "Filter By Fault Code"),
                React.createElement(TextInput, { type: "number", ref: faultCodeFilterInput, defaultValue: "" }),
                React.createElement("label", { className: "font-N7 text-black-600" }, "Max Peak Power"),
                React.createElement(TextInput, { type: "number", ref: maxPeakPowerInput, defaultValue: "" })))),
        React.createElement("div", { className: "flex flex-col w-fit gap-y-4" },
            React.createElement("div", { className: "ml-auto mr-4" },
                React.createElement(DowloadCSVButton, { resourceUrl: "".concat(resourceUrlBase, "/csv/").concat(dateRange[0].toISOString(), "/").concat(dateRange[1].toISOString()) })),
            React.createElement(TransactionTable, { onClickRow: function (row) { return navigate("/transaction/".concat(row.transactionId)); }, resourceUrl: "".concat(resourceUrlBase, "/").concat(dateRange[0].toISOString(), "/").concat(dateRange[1].toISOString(), "?").concat(apiSearchParams.toString()), maxRows: 20, isNoc: isNoc }))));
};
